import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'manual-burn-list',
    name: 'voucher-selling.manual-burn-list',
    meta: {
      title: 'Manual Burn List',
      action: 'read',
      subject: CASL.ACCESS.MANUAL_BURN_LIST
    },
    component: () => import('./list/ManualBurnListPage.vue')
  },
  {
    path: 'manual-burn-list/add',
    name: 'voucher-selling.manual-burn-list.add',
    meta: {
      title: 'Manual Burn List Add',
      action: 'create',
      subject: CASL.ACCESS.MANUAL_BURN_LIST
    },
    component: () => import('./form/ManualBurnFormPage.vue')
  },
  {
    path: 'manual-burn-list/:uuid',
    name: 'voucher-selling.manual-burn-list.detail',
    meta: {
      title: 'Manual Burn List Detail',
      action: 'read',
      subject: CASL.ACCESS.MANUAL_BURN_LIST
    },
    component: () => import('./detail/ManualBurnDetailPage.vue')
  }
]
