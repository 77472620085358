import CASL from '@/utils/constants/casl'

export default {
  path: 'solva-ai',
  name: 'solva-ai',
  meta: {
    title: 'Solva AI',
    action: 'read',
    subject: CASL.ACCESS.SOLVA_AI
  },
  component: () => import('@/pages/solva-ai/SolvaAiPage.vue')
}
