import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'voucher-selling-list',
    name: 'voucher-selling.voucher-selling-list',
    meta: {
      title: 'Voucher Selling List',
      action: 'read',
      subject: CASL.ACCESS.VOUCHER_SELLING
    },
    component: () => import('./list/VoucherSellingListPage.vue')
  },
  {
    path: 'voucher-selling-list/add',
    name: 'voucher-selling.voucher-selling-list.add',
    meta: {
      title: 'Voucher Selling List Add',
      action: 'create',
      subject: CASL.ACCESS.VOUCHER_SELLING
    },
    component: () => import('./form/VoucherSellingFormPage.vue')
  },
  {
    path: 'voucher-selling-list/:uuid',
    name: 'voucher-selling.voucher-selling-list.detail',
    meta: {
      title: 'Voucher Selling List Detail',
      action: 'read',
      subject: CASL.ACCESS.VOUCHER_SELLING
    },
    component: () => import('./detail/VoucherSellingDetailPage.vue')
  }
]
