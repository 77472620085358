import CASL from '@/utils/constants/casl'

export default [
  {
    path: 'member-list',
    name: 'voucher-selling.member-list',
    meta: {
      title: 'Member List',
      action: 'read',
      subject: CASL.ACCESS.MEMBER_VOUCHER_SELLING
    },
    component: () => import('./list/MemberListPage.vue')
  },
  {
    path: 'member-list/:uuid',
    component: () => import('./detail/MemberDetailPage.vue'),
    children: [
      {
        path: '',
        redirect: {
          name: 'voucher-selling.member-list.detail.member'
        }
      },
      {
        path: 'member',
        name: 'voucher-selling.member-list.detail.member',
        meta: {
          title: 'Member List Detail',
          action: 'read',
          subject: CASL.ACCESS.MEMBER_VOUCHER_SELLING
        },
        component: () => import('./detail/tab/MemberPage.vue')
      },
      {
        path: 'voucher',
        name: 'voucher-selling.member-list.detail.voucher',
        meta: {
          title: 'Member List Detail - Voucher',
          action: 'read',
          subject: CASL.ACCESS.MEMBER_VOUCHER_SELLING
        },
        component: () => import('./detail/tab/VoucherListPage.vue')
      },
      {
        path: 'share',
        name: 'voucher-selling.member-list.detail.share',
        meta: {
          title: 'Member List Detail - Share',
          action: 'read',
          subject: CASL.ACCESS.MEMBER_VOUCHER_SELLING
        },
        component: () => import('./detail/tab/ShareListPage.vue')
      }
    ]
  }
]
