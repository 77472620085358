import CASL from '@/utils/constants/casl'

export default {
  path: 'dashboard',
  name: 'dashboard',
  meta: {
    title: 'Dashboard',
    action: 'read',
    subject: CASL.ACCESS.DASHBOARD
  },
  component: () => import('@/pages/dashboard/DashboardPage.vue')
}
